import { Loader2 } from 'lucide-react';

const Loading = ({ height = 150, className = '' }) => {
  return (
    <div className={`flex items-center justify-center ${className}`} style={{ height: `${height}px` }}>
      <Loader2 className="animate-spin" />
    </div>
  );
};

const AppLoading = () => {
  return (
    <div className="min-h-[var(--page-height)] relative overflow-hidden">
      <div className="absolute left-0 w-full h-1 bg-zebel-primary-blue/30">
        <div className=" bg-zebel-primary-blue h-1 origin-left animate-progress z-1" />
      </div>
    </div>
  );
};

export { Loading, AppLoading };
