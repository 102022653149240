import { useJsApiLoader } from '@react-google-maps/api';

import { AppLoading } from '@/components/ui/loading';

const GoogleMapsLoader = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places'],
    googleMapsApiKey: 'AIzaSyBYJHu7sKU5Hc4Aw0nFYk9R4bUBS0Zzhg4'
  });

  if (!isLoaded) {
    return <AppLoading />;
  }

  return children;
};

export default GoogleMapsLoader;
