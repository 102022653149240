import { SearchX, TriangleAlert } from 'lucide-react';
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

const Error = ({ title = 'Error', message, status = 500 }) => {
  if (status === 404) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-1">
        <div className="rounded-full bg-muted p-3 border">
          <SearchX className="h-10 w-10" />
        </div>
        <div className="text-lg font-medium pt-1" data-cy="error-404-title">
          {title}
        </div>
        <div className="text-muted-foreground" data-cy="error-404-message">
          {message}
        </div>
      </div>
    );
  }

  return (
    <Alert variant="destructive">
      <TriangleAlert className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};

const ErrorBoundary = ({ children }) => {
  return (
    <RollbarErrorBoundary
      fallbackUI={() => <Error title="Something went wrong" message="An unexpected error occurred. Try reloading the page. Our team has been notified and is working on a fix." />}
    >
      {children}
    </RollbarErrorBoundary>
  );
};

export { Error, ErrorBoundary };
