import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { Error } from '@/components/ui/error';
import { Toaster } from '@/components/ui/toaster';
import { TooltipProvider } from '@/components/ui/tooltip';
import './index.css';
import GoogleMapsLoader from '@/app/googleMaps/GoogleMapsLoader';
import { getAuth0Domain, getAuth0ClientId, getAuth0RedirectUri, getAuth0Audience } from '@/helpers/auth0';
import { getRollbarConfig } from '@/helpers/rollbar';

import '@fontsource-variable/inter';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Auth0Provider
    domain={getAuth0Domain()}
    clientId={getAuth0ClientId()}
    authorizationParams={{
      redirect_uri: getAuth0RedirectUri(),
      audience: getAuth0Audience()
    }}
  >
    <Provider config={getRollbarConfig}>
      <ErrorBoundary fallbackUI={<Error />}>
        <BrowserRouter>
          <GoogleMapsLoader>
            <TooltipProvider>
              <App />
              <Toaster />
            </TooltipProvider>
          </GoogleMapsLoader>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  </Auth0Provider>
);
