import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import UserAvatar from '@/app/user/UserAvatar';
import { useCompany } from '@/data/company';
import { cn } from '@/helpers/shadcn';
import { useUserRoles } from '@/helpers/auth0';
import ProductIcon from '@/app/product/ProductIcon';

const AppHeader = () => {
  const location = useLocation();
  const company = useCompany();
  const { isZebeler } = useUserRoles();

  const links = [
    { to: '/project', label: 'Project Database' },
    { to: '/market', label: 'Market', hidden: !company.data.is_market_data_visible },
    { to: '/comparison', label: 'Compare', hidden: false },
    {
      to: '/v2-estimate',
      label: 'Estimate',
      hidden: !company.data.is_v2_estimate && !isZebeler
    },
    { to: '/settings', label: 'Settings', hidden: false }
  ];

  return (
    <header className="fixed top-0 left-0 right-0 border-b h-[var(--app-header-height)] flex items-center gap-3 px-4 z-[40] shadow bg-card">
      <div className="w-[250px] min-[250px] flex items-center gap-2">
        <Link to="/market">
          <img className="w-6 min-w-6" src="/assets/logos/zebel-logo-top-small-blue-bg.png" alt="Zebel logo" />
        </Link>
        <ChevronRight className="w-3 h-3 text-muted-foreground/70" />
        <div className=" font-medium text-sm truncate">{company.data.company_name}</div>
      </div>
      <nav className="flex-auto h-full flex items-center gap-6 justify-center text-sm">
        {links
          .filter((link) => !link.hidden)
          .map((link) => {
            return (
              <Link
                key={link.to}
                to={link.to}
                className={cn('transition-colors hover:text-foreground/80 font-medium', location.pathname.startsWith(link.to) ? '' : 'text-muted-foreground/80')}
              >
                {link.label}
              </Link>
            );
          })}
      </nav>
      <div className="w-[250px] min-[250px] flex items-center justify-end gap-4">
        <ProductIcon />
        <UserAvatar />
      </div>
    </header>
  );
};

export default AppHeader;
