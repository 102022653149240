import { Link, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';
import { Alert, AlertDescription, AlertTitle } from './alert';

const Page = ({ children }) => {
  return <main className="flex min-h-[var(--page-height)] pt-[var(--app-header-height)]">{children}</main>;
};

const PageContentError = () => {
  return (
    <Alert>
      <AlertTitle>An Error Occurred</AlertTitle>
      <AlertDescription>Something went wrong. Please try refreshing the page. If the problem persists, please contact support.</AlertDescription>
    </Alert>
  );
};

const PageContent = ({ children }) => {
  return (
    <div className="p-4 flex-auto">
      <ErrorBoundary fallbackUI={PageContentError}>{children}</ErrorBoundary>
    </div>
  );
};

const PageSidebar = ({ children }) => {
  return (
    <div className="w-[var(--page-sidebar-width)] min-w-[var(--page-sidebar-width)] bg-background border-r z-1 p-2">
      <nav className="sticky top-[var(--app-header-height)] max-h-[var(--page-height)] space-y-8">{children}</nav>
    </div>
  );
};

const PageLoading = () => {
  return (
    <div className="min-h-[var(--page-height)] pt-[var(--app-header-height)] relative overflow-hidden">
      <div className="absolute top-[var(--app-header-height)] left-0 w-full h-1 bg-zebel-primary-blue/30">
        <div className=" bg-zebel-primary-blue h-1 origin-left animate-progress z-1" />
      </div>
    </div>
  );
};

const PageSidebarGroup = ({ children }) => {
  return <div className="grid grid-flow-row auto-rows-max gap-0.5 text-sm">{children}</div>;
};

const PageSidebarGroupTitle = ({ children }) => {
  return <div className="rounded-md px-2 py-1 text-xs font-semibold">{children}</div>;
};

const PageSidebarLink = ({ children, to }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);

  return (
    <Link
      to={to}
      data-active={isActive}
      className="group flex h-8 w-full items-center rounded-lg px-2 font-normal underline-offset-2 hover:bg-accent hover:text-accent-foreground data-[active=true]:bg-accent/50 data-[active=true]:text-accent-foreground data-[active=true]:font-medium [&>svg]:h-4 [&>svg]:w-4 gap-2"
    >
      {children}
    </Link>
  );
};

export { Page, PageSidebar, PageContent, PageLoading, PageSidebarLink, PageSidebarGroup, PageSidebarGroupTitle };
