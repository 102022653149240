import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useSelf, useIsUserSSO } from '@/data/user';
import EditUserForm from '@/app/user/EditUserForm';
import EditUserPasswordForm from '@/app/user/EditUserPasswordForm';

const AccountDialog = ({ setOpen }) => {
  const self = useSelf();
  const isUserSSO = useIsUserSSO();

  return (
    <Dialog open onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>My Account</DialogTitle>
          <DialogDescription>{self.data.email}</DialogDescription>
        </DialogHeader>
        {isUserSSO ? (
          <EditUserForm setOpen={setOpen} />
        ) : (
          <Tabs defaultValue="profile">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="profile">Profile</TabsTrigger>
              <TabsTrigger value="password">Password</TabsTrigger>
            </TabsList>
            <TabsContent value="profile">
              <EditUserForm setOpen={setOpen} />
            </TabsContent>
            <TabsContent value="password">
              <EditUserPasswordForm setOpen={setOpen} />
            </TabsContent>
          </Tabs>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccountDialog;
