import { Suspense, lazy, Component } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';

import { Loader2 } from 'lucide-react';
import AppHeader from './components/appHeader/AppHeader';
import { PageLoading } from './components/ui/page';

import { useCompany } from './data/company';
import { useSelf } from './data/user';
import { Error } from './components/ui/error';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { useAuth } from '@/helpers/auth0';
import { AppLoading } from '@/components/ui/loading';
// Helper function to reload the page with a retry parameter
const reloadPage = () => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('retry', '1');
  window.location.href = currentUrl.href;
};

// Helper function to check if the page has been reloaded with the retry parameter
const hasRetried = () => {
  const currentUrl = new URL(window.location.href);
  return currentUrl.searchParams.get('retry') === '1';
};

class CodeSplitErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, countdown: 3 };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidUpdate() {
    if (this.state.hasError && this.state.countdown > 0) {
      setTimeout(() => {
        this.setState((state) => ({ countdown: state.countdown - 1 }));
      }, 1000);
    } else if (this.state.hasError && this.state.countdown === 0 && !hasRetried()) {
      reloadPage();
    }
  }

  render() {
    if (this.state.hasError) {
      if (hasRetried()) {
        return (
          <div className="flex h-screen w-full items-center justify-center max-w-sm mx-auto">
            <Error message="An error occurred loading the app" />
          </div>
        );
      }
      return (
        <div className="flex h-screen w-full items-center justify-center">
          <Card>
            <CardHeader>
              <CardTitle>New app version available</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="text-center flex items-center justify-center gap-2 text-sm">
                <Loader2 className="animate-spin w-4 h-4 text-muted-foreground" />
                <p>Reloading...</p>
              </div>
            </CardContent>
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}

const loadComponentWithRetry = (importFunc) => {
  const Component = lazy(() =>
    importFunc().catch((error) => {
      if (!hasRetried()) {
        // Wait a second and try once more
        return new Promise((resolve) => setTimeout(() => resolve(importFunc()), 1000));
      }
      throw error;
    })
  );

  return (props) => (
    <CodeSplitErrorBoundary>
      <Suspense fallback={<PageLoading />}>
        <Component {...props} />
      </Suspense>
    </CodeSplitErrorBoundary>
  );
};

const AccountSetupPage = loadComponentWithRetry(() => import('./app/accountSetup/Page'));
const SettingsPage = loadComponentWithRetry(() => import('./app/settings/Page'));
const LoginPage = loadComponentWithRetry(() => import('./app/login/Page'));
const EstimatePage = loadComponentWithRetry(() => import('./app/estimate/Estimate'));
const WhatsNewPage = loadComponentWithRetry(() => import('./app/product/ListProductNews'));
const ProjectsRoutes = loadComponentWithRetry(() => import('./app/project/Routes'));
const ProjectRecordsRoutes = loadComponentWithRetry(() => import('./app/projectRecord/Routes'));
const ViewMarketPage = loadComponentWithRetry(() => import('./app/market/ViewMarketPage'));
const ComparisonRoutes = loadComponentWithRetry(() => import('./app/comparison/Routes'));

const AuthenticatedRoutes = () => {
  const history = useHistory();
  const { authStrategy, isAuthenticated, isLoading, loginWithRedirect } = useAuth();

  if (isLoading) {
    return <AppLoading />;
  }

  if (!isAuthenticated) {
    if (authStrategy === 'zebel-api') {
      history.push('/login');
    } else {
      loginWithRedirect();
    }
    return null;
  }

  return (
    <UserLoader>
      <AppHeader />
      <ErrorBoundary fallbackUI={<Error />}>
        <Switch>
          <Redirect exact from="/" to="/project" />
          {/* todo: auth0 still redirects to /projects, but it needs to be tested lots before changing */}
          <Redirect exact from="/projects" to="/project" />

          <Route path="/project" component={ProjectsRoutes} />
          <Route path="/project-record" component={ProjectRecordsRoutes} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/market" component={ViewMarketPage} />
          <Route path="/v2-estimate" component={EstimatePage} />
          <Route path="/updates" component={WhatsNewPage} />
          <Route path="/comparison" component={ComparisonRoutes} />
        </Switch>
      </ErrorBoundary>
    </UserLoader>
  );
};

const UserLoader = ({ children }) => {
  const company = useCompany();
  const self = useSelf();

  if (company.isLoading && !company.data) {
    return <AppLoading />;
  }

  if (company.error) {
    return <Error message="An error occurred loading company" />;
  }

  if (self.isLoading && !self.data) {
    return <AppLoading />;
  }

  // todo: this can be refactored as an auth check instead
  if (self.error) {
    return <Error message="An error occurred loading user" />;
  }

  return children;
};

const ActivateRoutes = () => {
  return (
    <Switch>
      <Route path="/users/activate/:activationUid" component={AccountSetupPage} />
    </Switch>
  );
};

const LoginRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
    </Switch>
  );
};

export { AuthenticatedRoutes, ActivateRoutes, LoginRoutes };
