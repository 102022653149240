import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { useAsyncEffect } from 'rooks';
import axios from 'axios';

import { useToast } from './toast';
import { getCookieDomain } from './api';
import { useSelf } from '@/data/user';

const getAuth0ClientId = () => {
  let clientId = '';
  if (window.location.origin.includes('app.zebel.io')) clientId = 'u4uRABrbYzu1SKcQkjMH5ZvNmDmXUCf8';
  if (window.location.origin.includes('app.dev.zebel.io')) clientId = 'joSYCRkVvo3GaLOm0JxQKscpGj8g3nyr';
  if (window.location.origin.includes('amazon.zebel.io')) clientId = 'joSYCRkVvo3GaLOm0JxQKscpGj8g3nyr';
  if (window.location.origin.includes('local')) clientId = '7wgmvCRWSlGLAHG06VE12M8ijN9JoIlV';

  return clientId;
};

const getAuth0RedirectUri = () => {
  return `${window.location.origin}/projects`;
};

const getAuth0Audience = () => {
  let aud = '';
  if (window.location.origin.includes('app.zebel.io')) aud = 'https://api.zebel.io';
  if (window.location.origin.includes('app.dev.zebel.io')) aud = 'https://api.dev.zebel.io';
  if (window.location.origin.includes('amazon.zebel.io')) aud = 'https://api.dev.zebel.io';
  if (window.location.origin.includes('local')) aud = 'https://app.eng.zebel.local:5000';
  return aud;
};

const getAuth0Domain = () => {
  let domain = '';
  if (window.location.origin.includes('app.zebel.io')) domain = 'zebel.us.auth0.com';
  if (window.location.origin.includes('app.dev.zebel.io')) domain = 'zebel-dev.us.auth0.com';
  if (window.location.origin.includes('amazon.zebel.io')) domain = 'zebel-dev.us.auth0.com';
  if (window.location.origin.includes('local')) domain = 'zebel-dev.us.auth0.com';
  return domain;
};

const useAuth = () => {
  // eslint-disable-next-line no-undef
  const authStrategy = process.env.VITE_AUTH_STRATEGY;
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const [isHttpLoading, setIsHttpLoading] = useState(true);
  const [isHttpReady, setIsHttpReady] = useState(false);

  let isAuthenticatedZebel = false;
  let zebelToken;
  if (authStrategy === 'zebel-api') {
    zebelToken = Cookies.get('token');
    if (zebelToken) {
      isAuthenticatedZebel = true;
    }
  }

  const initializeHttp = async () => {
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(async (config) => {
      let apiToken;
      if (authStrategy !== 'zebel-api') {
        apiToken = await getAccessTokenSilently();
        apiToken = apiToken.replace(/-/g, '+').replace(/_/g, '/');
      }

      config.headers['Authorization'] = `Bearer ${isAuthenticatedZebel ? zebelToken : apiToken}`;
      return config;
    });
    setIsHttpReady(true);
    setIsHttpLoading(false);
  };

  useAsyncEffect(async () => {
    if (!isLoading && !isHttpReady) {
      await initializeHttp();
    }
  }, [isLoading, isHttpReady]);

  return {
    authStrategy,
    isAuthenticated: authStrategy === 'zebel-api' ? isAuthenticatedZebel : isAuthenticated,
    isLoading: isLoading || isHttpLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout
  };
};

const useUser = () => {
  // eslint-disable-next-line no-undef
  const authStrategy = process.env.VITE_AUTH_STRATEGY;
  const self = useSelf();

  let zebelToken;
  if (authStrategy === 'zebel-api') {
    zebelToken = Cookies.get('token');
    if (zebelToken) {
      const decoded = jwtDecode(zebelToken);
      return {
        // firstname: decoded?.firstname, // not included in zebelToken
        // lastname: user?.lastname, // not included in zebelToken
        company_uid: decoded?.['zebel.io/company_uid']?.replaceAll('-', ''),
        company_name: decoded?.['zebel.io/company_name'],
        roles: decoded?.['zebel.io/roles'],
        email: decoded?.['zebel.io/user_email'],
        user_uid: decoded?.['zebel.io/user_uid']?.replaceAll('-', '')
      };
    }
  }

  return {
    firstname: self.data.firstname,
    lastname: self.data.lastname,
    company_uid: self.data.company.replaceAll('-', ''),
    roles: self.data.roles,
    email: self.data.email,
    user_uid: self.data.uid.replaceAll('-', '')
  };
};

const useUserRoles = () => {
  const user = useUser();
  return {
    hasAdminAccess: user.roles[0] === 'admin',
    hasEditorAccess: ['admin', 'editor'].includes(user.roles[0]),
    isZebeler: user.roles[1] === 'zebeler'
  };
};

const useLogoutUser = () => {
  const { toast } = useToast();
  const { logout } = useAuth0();

  const logoutUser = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove('token', { domain: getCookieDomain() });
      await logout({
        logoutParams: {
          returnTo: window.location.origin // If you change this you need to update auth0 settings
        }
      });
    } catch (err) {
      toast({ title: 'Error', description: 'An error occurred logging out. Please try again.', variant: 'destructive' });
    }
  };

  return logoutUser;
};

export { getAuth0ClientId, getAuth0RedirectUri, getAuth0Audience, getAuth0Domain, useAuth, useUser, useUserRoles, useLogoutUser };
