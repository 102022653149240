import Cookies from 'js-cookie';

const news = [
  {
    date: 'March 28th, 2025',
    title: 'New Compare Page',
    description: `<p>We've completely rebuilt the Compare page! The new page features a new design, enhanced performance, new charting features, and much more. For a full demo of the new compare page, please reach out to support.</p>`
  },
  {
    date: 'March 17th, 2025',
    title: 'Enhanced Escalation Features',
    description:
      '<p>Introducing the ability to leverage multiple escalation tables. You can now easily select the desired escalation table within a record template, and when creating estimates from that template, the selected escalation table will be used.</p>'
  },
  {
    date: 'March 7th, 2025',
    title: 'Default Value Method for Design Fields',
    description:
      '<p>Design fields can now be configured to automatically populate their initial values from comps using statistical methods. When setting up a template, you can choose from Sum, Min, Max, Average, or Median methods which will set the initial design field value when creating an estimate from that template. If the design field is driven by a formula, the formula will still take precedence.</p><p>If you would like a demo or more information, please reach out to support.</p>'
  },
  {
    date: 'March 7th, 2025',
    title: 'Estimate Comp Improvements',
    description: `<p>Settings for estimate comps have been moved out of the sidebar and into the estimate settings page. Comps can now also be edited directly from the detailed budget by clicking on a comp.</p>`
  },
  {
    date: 'March 6th, 2025',
    title: 'Indirect Cost Improvements',
    description:
      '<p>The Indirect Cost Settings have been relocated from the estimate sidebar to the estimate settings page, providing more space for managing indirect costs. Additionally, indirect cost formulas can now be edited directly from the detailed budget by clicking on an indirect total cost.</p>'
  },
  {
    date: 'March 6th, 2025',
    title: 'Indirect Cost Formula Improvements',
    description: '<p>Indirect cost formulas can now reference design fields inside of estimates.</p>'
  },
  {
    date: 'March 5th, 2025',
    title: 'Estimate Design Data Improvements',
    description:
      '<p>A new page has been added to estimate settings, providing a list of design fields that are not included in the estimate programming. This makes it easier to review and manage design data that falls outside of the typical programming items.</p>'
  },
  {
    date: 'March 4th, 2025',
    title: 'Revamped Market Data Page',
    description: `<p>We've completely redesigned the Market Data page to offer a cleaner look, multi-sector support, and significantly improved performance. Additionally, estimates and records now include a Market tab, allowing for seamless comparisons with market data.</p>`
  },
  {
    date: 'February 11th, 2025',
    title: 'Estimate Tag Enhancements',
    description: `<p>A new settings tab in Estimates lets you manage tags directly. While tags can still be set at the template level, they can now also be customized per estimate.</p>`
  },
  {
    date: 'February 10th, 2025',
    title: 'Consolidated Estimate Improvements',
    description: `<p>There is a new tab on consolidated estimates that will display the design data for each child estimate. The design data can also be exported to a CSV file.</p>`
  },
  {
    date: 'February 6th, 2025',
    title: 'Estimate Detailed Budget Improvements',
    description: `<p>In the detailed budget section of the estimate, you can now break down total direct and indirect costs by any unit of measure. The unit cost for each will also be displayed.</p>`
  },
  {
    date: 'February 3rd, 2025',
    title: 'Custom Cost Filters',
    description: `<p>Building on the custom design filters mentioned below, you can now create and manage custom cost filters in the settings area. These new filters let you refine projects and records based on total costs across the platform, expanding your ability to organize and analyze data your way.</p>`
  },
  {
    date: 'February 3rd, 2025',
    title: 'Edit Indirect Cost Labels',
    description: `<p>You can now edit indirect cost labels from within the estimate, making it easier to make small adjustments if necessary.</p>`
  },
  {
    date: 'February 3rd, 2025',
    title: 'Custom Value Units',
    description: `<p>Custom value units now allow up to 5 decmimal places.</p>`
  },
  {
    date: 'January 31st, 2025',
    title: 'Estimates List & Filter Improvements',
    description: `<p>We've enhanced the estimate list page with new custom design filters (details below). Additionally, a new project database filter lets you sort records by historical data or estimates.</p>`
  },
  {
    date: 'January 31st, 2025',
    title: 'Custom Design Filters',
    description:
      '<p>You can now create and manage custom filters for design data in the settings area, making it easier to filter projects and records across the platform. Plus, you can reorder filters for better organization. Stay tuned - custom cost data filters are coming soon!</p>'
  },
  {
    date: 'January 31st, 2025',
    title: 'Project Database Now Groups Consolidated Estimates',
    description:
      "<p>We've improved the project database to better organize estimates! Child estimates are now automatically grouped under their respective consolidated estimates, making it easier to manage and review related data. Additionally, child estimates can be reordered directly within the project database for greater flexibility."
  },
  {
    date: 'January 23rd, 2025',
    title: 'The New Project Database is Here!',
    description: `
      <div class='space-y-2'>
  <p>We’ve rebuilt the project database with some exciting improvements:</p>
  <ul class='pl-4 mb-2'>
    <li><span class='font-medium'>New Design:</span> A clean, updated look for easier navigation.</li>
    <li><span class='font-medium'>Faster Performance:</span> Enjoy quicker load times and smoother interactions.</li>
    <li><span class='font-medium'>Improved Filters:</span> Sort and filter projects and records more effectively.</li>
    <li><strong class='font-medium'>Archiving:</strong> You can now archive projects and records to stay organized.</li>
    <li><strong class='font-medium'>New Dashboard:</strong> The record page now features a dashboard that summarizes record data for quick insights.</li>
  </ul>
  <p class="coming-soon">Coming soon: Custom filters to let you filter projects and records by any of your design fields!</p>
      </div>
    `
  },
  {
    date: 'January 23rd, 2025',
    title: 'Revamped Cost Data Page',
    description: `<p>We've completely rebuilt the Cost Data page inside of the Project Database. The new page features a new design, enhanced performance, bulk actions, and much more.</p>`
  },
  {
    date: 'January 16th, 2025',
    title: 'Bid Management is Now Available 🎉',
    description: `<p>We’re excited to announce that our Bid Management feature is now available to all existing customers! To explore how it works, you can request a demo or have the feature activated on your account by contacting our support team.</p>`
  },
  {
    date: 'January 16th, 2025',
    title: 'Revamped Design Data Page',
    description: `<p>We’ve completely rebuilt the Design Data page in the Project Database! The new page features a new design, enhanced performance, bulk action capabilities, and much more. Stay tuned — an updated Cost Data page is coming soon!</p>`
  },
  {
    date: 'January 1st, 2025',
    title: 'V1 Estimate - Depreciation',
    description: `<p>Legacy V1 estimates can no longer be created. Existing V1 estimates will be available to view until mid-2025 (exact date to be announced). If you are still using V1 estimates, please reach out to support for assistance upgrading to V2 estimates.</p>`
  },
  {
    date: 'December 17th, 2024',
    title: 'Record Template - Detail Row Notes',
    description: `<p>Notes can now be added to detail rows in the record template settings. Any notes added in the template will be copied over to the estimate when the template is used.</p>`
  },
  {
    date: 'December 3rd, 2024',
    title: 'Record Template - Duplicate',
    description: `<p>Record templates can now be duplicated. Duplicating a template will copy over all cost & design data, estimate configuration, formulas, tags, and more.</p>`
  },
  {
    date: 'November 29th, 2024',
    title: 'V1 Estimate - Depreciation',
    description: `<p>We are phasing out V1 estimates. Starting January 1, 2025, it will no longer be possible to create new V1 estimates. However, you will still be able to view existing V1 estimates until mid-2025 (exact date to be announced).</p>`
  },
  {
    date: 'November 28th, 2024',
    title: 'V2 Estimate - Detail Row Unit Cost Formulas',
    description: `<p>Unit cost formulas are now supported for detail rows in V2 estimates.</p>`
  },
  {
    date: 'November 26th, 2024',
    title: 'V2 Estimate - Detail Row Negative Values',
    description: `<p>Detail rows now support unit cost values that are less than zero.</p>`
  },
  {
    date: 'November 22nd, 2024',
    title: 'V2 Estimate - Default Detail Estimating',
    description: `<p>
You can now enable detail estimating by default for specific cost codes directly within the template settings. This can be configured on a per-cost-code basis or applied to multiple cost codes at once using the bulk update option. When detailed estimating is turned on by default, any estimate you create will automatically start in detail estimating mode.</p>`
  },
  {
    date: 'November 19th, 2024',
    title: 'V2 Estimate - Bid Management',
    description:
      'We’re excited to introduce our new bid management feature, now available in beta for V2 estimates! This powerful tool lets you efficiently manage bids tied to cost codes and detail rows across your estimate. You can organize bids within bid packages, compare them, and easily set the preferred or selected bid for each package. If you’re interested in joining the beta or would like a demo, please contact our support team.'
  },
  {
    date: 'November 8th, 2024',
    title: 'V2 Estimate - Improved Detailed Budget Layout Preferences',
    description: `<p>We've added the ability to show or hide notes, files and tag icons for cost codes and detail rows in the layout preferences for V2 estimates. More advanced detailed budget layout preferences will be coming soon.</p>`
  },
  {
    date: 'November 7th, 2024',
    title: 'New Directory Page',
    description: `<p>We've added a new Directory page in the Settings area! This page will help you manage contacts more effectively and lays the groundwork for our upcoming Bid Management feature.</p>`
  },
  {
    date: 'November 6th, 2024',
    title: 'V2 Estimate - View Cost Codes By Tag',
    description: `<p>We've introduced a new feature in V2 estimates that lets you view all cost codes linked to a tag directly on the estimate analytics page. This update simplifies finding and managing cost codes associated with specific tags.</p>`
  },
  {
    date: 'November 5th, 2024',
    title: 'V2 Estimate - Tagging Improvements',
    description:
      "<p>We've added the ability to quick edit a tag in v2 estimates by clicking on a tag and selecting a new tag. This should speed up the process of updating tags for cost codes.</p>"
  },
  {
    date: 'November 5th, 2024',
    title: 'V2 Estimate - Tag Detail Rows',
    description: `<p>Tagging is now available for detail rows in V2 estimates. This allows for more flexibility in how cost data is tracked and reported. If you'd like a demo or more information, please reach out to support.</p>`
  },
  {
    date: 'November 5th, 2024',
    title: 'Record Templates',
    description: `
  <p>We've fully redesigned the Cost and Design Data section in record templates to make managing and updating cost and design information easier than ever. This update introduces powerful new features, including:</p>
  
  <ul class='list-decimal list-inside pl-4 mb-2'>
    <li>Enhanced control over detail rows:
      <ul class='list-disc list-inside pl-4'>
        <li>Add, tag, bulk edit, and delete rows</li>
        <li>Set market links, units of measure (UOMs), and tags in bulk</li>
      </ul>
    </li>
    
    <li>Streamlined field management:
      <ul class='list-disc list-inside pl-4'>
        <li>Bulk edit, move between categories, or delete fields</li>
        <li>Bulk assign market links, UOMs, and tags</li>
      </ul>
    </li>
    
    <li>Improved user experience:
      <ul class='list-disc list-inside pl-4'>
        <li>Quickly add multiple categories, fields, and detail rows at once</li>
      </ul>
    </li>
  </ul>
  
  <p>For more details on these updates, please reach out to our support team.</p>

`
  },
  {
    date: 'October 31st, 2024',
    title: 'V2 Estimate - Tagging',
    description: `<p>Tagging is now available in V2 estimates. You can tag cost codes to enable more detailed analytics, helping you gain insights by tracking key metrics. Tagging for sub-cost fields is coming soon. If you'd like assistance or a demo, please reach out to support.</p>`
  },
  {
    date: 'October 29th, 2024',
    title: 'V2 Estimate - Performance Improvements',
    description: `<p>We've been working hard to improve the overall performance of v2 estimates. Selecting a template while creating a new estimate is now 60% faster than before.</p>`
  },
  {
    date: 'October 23rd, 2024',
    title: 'V2 Estimate Unit Cost Formulas - Cost Categories',
    description: `<p>Unit cost formulas in v2 estimates now support referencing cost categories. This allows for more flexibility in how unit costs are calculated.</p>`
  },
  {
    date: 'October 22nd, 2024',
    title: 'SSO OpenID Connect',
    description: `<p>Zebel now supports SSO OpenID Connect authentication. Please reach out to support for more information.</p>`
  },
  {
    date: 'October 18th, 2024',
    title: 'File Upload Improvements',
    description: `<div>
      <p>File uploads across Zebel have been completelty rebuilt. The new file uploader is faster, more reliable, and supports a wider variety of file types. Some of the new features include:</p>
      <ul class='list-disc list-inside'>
        <li>PDFs and images can now be previewed in the browser before downloading</li>
        <li>The uploader supports drag and drop, and can upload multiple files at once</li>
        <li>The uploader will automatically retry failed uploads, and will alert you if any uploads fail</li>
        <li>Files can now be attached to cost codes in v2 estimates</li>
      </ul>
      </div>`
  },
  {
    date: 'October 18th, 2024',
    title: 'Custom Value Units',
    description: `
      <p>Value units for design fields can now be customized in settings. This will allow for more flexibility for how many decimal places are displayed. Please reach out to support if you need help configuring these settings.</p>
      `
  },
  {
    date: 'October 11th, 2024',
    title: 'V2 Estimate Consolidation',
    description: `
      <p>
      V2 estimates now support consolidation. This allows you to combine multiple estimates into a single consolidated estimate. Please reach out to support if you need help with this feature.
      </p>`
  },
  {
    date: 'October 8th, 2024',
    title: 'V2 Estimate Indirect Cost Templates',
    description: `
      <p>
      Indirect costs for V2 estimates can now be setup inside of the record template settings. This will allow indirect costs to be more dynamic and flexible, as they now support custom formulas.
      </p>`
  },
  {
    date: 'October 1st, 2024',
    title: 'V2 Estimate Unit Cost Formulas',
    description: `
      <p>
     Custom formulas can now be used for unit cost overrides in V2 estimates. This will allow for more flexibility in how unit costs are calculated.
      </p>`
  }
];

const cookieName = 'zebel-news-last-seen-title';

const hasUnseenNews = () => {
  const lastSeenTitle = Cookies.get(cookieName);
  return !lastSeenTitle || news[0].title !== lastSeenTitle;
};

const updateLastSeenNews = () => {
  if (news.length > 0) {
    Cookies.set(cookieName, news[0].title, { expires: 365 });
  }
};

export { news, hasUnseenNews, updateLastSeenNews };
